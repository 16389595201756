<template>
  <transition name="shareShow">
    <div class="comment" :style="[{position:absolute ?'absolute' :null}]" @click="$emit('membershipClose')">
      <transition name="commentShow">
        <div class="content" @click.stop v-if="membershipShow">
          <div class="top">
            <div class="title flex-column">
              <div class="heng"></div>
              <ImgDecypt class="top-img" :src="topicInfo.author&&topicInfo.author.avatarUrl" />
              <p class="change">{{topicInfo.author&&topicInfo.author.nickName}}</p>
              <p class="become">成为会员即可查看高清图片</p>
              <div @click="toVip" class="btn">开通会员</div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    topicInfo: {
      //话题博主信息
      type: Object,
      default: () => {
        return {};
      },
    },
    membershipShow: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toVip() {
      let vipGrap = {
        type: "video",
        vInfo: this.topicInfo
      }
      sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap))
      this.$router.push("/viplist");
    }
  }
};
</script>
<style lang="scss" scoped>
.comment {
  position: fixed;
  z-index: 6;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.4);
}
.content {
  position: absolute;
  bottom: 0;
  height: 240px;
  padding: 0 16px;
  width: 100%;
  background: #ffffff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-sizing: border-box;
}
.title {
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 14px 0 10px;
}
.heng {
  display: inline-block;
  width: 110px;
  height: 6px;
  border-radius: 6px;
  background: #e0e5eb;
}
.top-img {
  width: 46px;
  height: 46px;
  margin-top: 20px;
  border-radius: 50%;
  border: 1px solid #f21313;
  overflow: hidden;
}
.change {
  margin-top: 10px;
}
.become {
  margin-top: 16px;
}
.btn {
  width: 100%;
  margin-top: 20px;
  height: 30px;
  border-radius: 30px;
  line-height: 30px;
  color: #ffffff;
  background: $vermillion;
  font-size: 14px;
}
</style>