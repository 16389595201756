<template>
  <div class="blogger_detalis">
    <!-- header -->
    <div class="header">
      <div class="back" @click="$router.go(-1)">
        <svg-icon iconClass="leftArrow" class="leftArrow"></svg-icon>
      </div>
      <transition name="shareShow">
        <ImgDecypt :src="blogger.backgroud" v-if="blogger.backgroud" />
      </transition>
    </div>
    <div class="blogger_info">
      <div class="blogger-avatar">
        <ImgDecypt :src="blogger.avatarUrl" :round="true" class="avatar" :key="blogger.avatarUrl" />
        <img :src="avatarFrame" class="avatarFrame">
      </div>
      <h2>{{blogger.nickName ?blogger.nickName : "未知用户"}}</h2>
      <div class="blogger-work-info">
        <div class="blogger-fan">
          <p>{{blogger.likes|watchCount}}</p>
          <p>获赞</p>
        </div>
        <div class="blogger-fan">
          <p>{{blogger.cares|watchCount}}</p>
          <p>关注</p>
        </div>
        <div class="blogger-fan">
          <p>{{blogger.works|watchCount}}</p>
          <p>发布</p>
        </div>
      </div>
    </div>
    <!-- tab -->
    <div class="blogger_info-main">
      <van-sticky>
        <div class="mt-tabs-box">
          <div class="my-tabs">
            <div :class="['my-tab',{'active': myTabActive == 0}]" @click="clickTab(0)">喜欢</div>
            <div :class="['my-tab',{'active': myTabActive == 1}]" @click="clickTab(1)">帖子</div>
          </div>
        </div>
        <div class="line"></div>
      </van-sticky>
    </div>
    <div class="bottom-all">
      <v-touch @swipeleft.self="swipeLeft" @swiperight.self="swiperight" :swipe-options="{direction: 'horizontal'}">
        <transition :name="myTabtransition">
          <BloggerOrgin v-if="myTabActive == 0" class="bottom-content" :key="myTabActive"></BloggerOrgin>
          <BloggerPosts v-else class="bottom-content" :key="myTabActive"></BloggerPosts>
        </transition>
      </v-touch>
    </div>
  </div>
</template>
<script>
import { getBlogInfo, sendUserCare } from "@/api/on_demand.js";
import { Toast } from "vant";
import BloggerOrgin from "./bloggerOrgin.vue";
import BloggerPosts from "./bloggerPos.vue";
export default {
  components: {
    BloggerOrgin,
    BloggerPosts,
  },
  data() {
    return {
      blogger: {},
      avatarFrame: require("@/assets/png/avatarFrame2.png"),
      myTabActive: 0,
      myTabtransition: "slide-right",
      isfanShow: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    //获取数据
    async getData() {
      let req = {
        bloggerId: Number(this.$route.query.id),
      };
      let ret = await this.$Api(getBlogInfo, req);
      if (ret.code == 200) {
        this.blogger = ret.data.blogger;
      }
    },
    clickTab(active) {
      if (active > this.myTabActive) {
        this.myTabtransition = "slide-left";
      } else {
        this.myTabtransition = "slide-right";
      }

      this.myTabActive = active;
    },
    // 关注
    async focus() {
      let isCared = this.blogger.cared;
      let req = {
        userId: Number(this.$route.query.id),
        isCare: !isCared,
      };
      let ret = await this.$Api(sendUserCare, req);
      if (ret.code == 200) {
        this.blogger.cared = !isCared;
        if (!isCared) {
          this.blogger.cares++;
        } else {
          this.blogger.cares--;
        }
        Toast(!isCared ? "关注成功" : "取消关注");
        return;
      }
      Toast(ret.tip);
    },
    // 监听左滑事件
    swipeLeft() {
      this.myTabActive++;
      if (this.myTabActive > 2) {
        this.myTabActive = 2;
      }
      this.myTabtransition = "slide-left";
    },
    // 监听右滑事件
    swiperight() {
      this.myTabActive--;
      if (this.myTabActive < 0) {
        this.myTabActive = 0;
      }
      this.myTabtransition = "slide-right";
    },
    // 加入粉丝团
    joinFan() {
      if (this.blogger.fansGroup.isMember) {
        this.myTabActive = 1;
      } else {
        this.isfanShow = true;
      }
    },
    // 粉丝团购买成功回调
    fanSuc() {
      this.blogger.fansGroup.members++;
      this.blogger.fansGroup.isMember = true;
      this.isfanShow = false;
      this.myTabActive = 1;
      Toast("加入粉丝团成功！");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-sticky {
  background: #fff;
}
/* 淡隐淡现 */
.shareShow-enter-active,
.shareShow-leave-active {
  transition: all 0.3s linear;
}

.shareShow-enter-to,
.shareShow-leave {
  opacity: 1;
}

.shareShow-leave-to,
.shareShow-enter {
  opacity: 0;
}
.blogger_detalis {
  // position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .leftArrow {
    top: 20px;
    left: 15px;
    width: 20px;
    height: 20px;
    color: #fff;
    font-size: 20px;
    position: fixed;
    padding: 4px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9;
  }
  .header {
    background: url("../../assets/png/borker_bg.png");
    background-size: 100%;
    width: 100%;
    height: 120px;
  }
  .blogger-avatar {
    margin: -30px auto 0;
    width: 70px;
    height: 70px;
    padding: 5px;
    box-sizing: border-box;
    position: relative;
    .avatarFrame {
      position: absolute;
      top: -2px;
      left: 0;
      width: 70px;
      height: 70px;
    }
  }
  .blogger_info {
    h2 {
      width: 320px;
      margin: auto;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 4px 0 10px 0;
    }
    .blogger-work-info {
      width: 320px;
      margin: auto;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      color: #252525;
      .blogger-fan {
        width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        p:first-child {
          padding-bottom: 3px;
        }
      }
    }
    .nocares {
      background: #a0a0a0 !important;
    }
    .blogger-focus {
      margin: 10px;
      height: 29px;
      line-height: 29px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      border-radius: 12px;
      background-image: linear-gradient(to right, red, $vermillionFour);
    }
    .blogger-introduce {
      width: 300px;
      height: 50px;
      font-size: 12px;
      color: #707070;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      p {
        width: 100%;
        line-height: 18px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; //多行在这里修改数字即可
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      }
    }
    // 加入粉团
    .join-fan {
      display: flex;
      height: 30px;
      margin: 0 10px;
      align-items: center;
      justify-content: center;
      .join-fan-left {
        display: flex;
        align-items: center;
        .fan-avators {
          display: flex;
          margin-left: 10px;
          align-items: center;
          .fan-avator {
            width: 30px;
            height: 30px;
            margin-left: -10px;
          }
        }
        .join-fan-info {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 180px;
        }
      }
      .join-fan-btn {
        background: $vermillion;
        color: #fff;
        width: 90px;
        font-size: 13px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 8px;
      }
    }
  }
  .line {
    width: 100%;
    height: 3px;
    margin: 10px 0 0 0;
    background: rgba(112, 112, 112, 0.16);
  }

  .blogger_info-main {
    margin: 20px 0 0 0;
    /deep/ .van-sticky {
      max-width: 540px;
      margin: auto;
    }
    .my-tabs {
      width: 100%;
      display: flex;
      .my-tab {
        cursor: pointer;
        flex: 1;
        text-align: center;
        font-size: 16px;
        position: relative;
        transition-duration: 0.3s;
        padding: 5px 0 0 0;
      }
    }
    .active {
      color: #000;
    }
    .active::after {
      position: absolute;
      content: "";
      display: inline-block;
      width: 26px;
      height: 4px;
      background: red;
      border-radius: 2px;
      bottom: -10px;
      left: 50%;
      margin-left: -14px;
    }
  }
}
.bottom-all {
  position: relative;
}
.bottom-content {
  position: absolute;
  width: 100%;
}

//粉丝团购买
.fan-box {
  text-align: center;
  border-radius: 8px 8px 0 0;
  .line {
    width: 100px;
    height: 5px;
    border-radius: 6px;
    background: #e4e4e4;
    margin: 10px auto;
  }
  h2 {
    padding: 10px 0 40px 0;
    font-size: 20px;
    color: #000;
  }
  .fan-name {
    width: 100%;
    padding: 10px 0;
  }
  .fan-info {
    background: #e4e4e4;
    padding: 10px;
    margin: 10px;
    line-height: 18px;
    border-radius: 8px;
    .fan-detail {
      text-align: left;
    }
  }
  .fan-jon-money {
    span {
      color: red;
    }
  }
  .join-btn {
    box-sizing: border-box;
    width: 90%;
    height: 36px;
    border-radius: 15px;
    margin: 10px 0 30px 0;
    font-size: 18px;
  }
}
/deep/ .van-popup--bottom {
  border-radius: 20px 20px 0 0;
}
</style> 