<template>
  <div class="dynamic-list">
    <div class="header flex-between">
      <div tag="div" class="left flex" @click="jumpUserDetail">
        <ImgDecypt class="left-header" :round="true" :src="item.author&&item.author.avatarUrl" />
        <div class="user">
          <div class="name">{{item.author&&item.author.nickName}}</div>
          <div class="time">{{item.createdAt|time}}</div>
        </div>
      </div>
      <div class="right" @click="userCare" v-show="!(item.author&&item.author.cared) && item.author.id !=userInfo.id">+关注</div>
      <div class="review-box" v-if="item.status && (item.status == 2 || item.status ==4)">
        <img :src="reivew[item.status]" alt="">
      </div>
    </div>
    <div class="sec ellipsis">{{item.content}}</div>
    <div class="tag ellipsis flex" v-if="!item.repostMediaInfo||(item.repostMediaInfo&&item.repostMediaInfo.mType==1)"><span class="tag-w" v-for="(items,index) in item.topics" :key="index">#{{items}}</span></div>
    <div class="tag ellipsis flex" v-else><span class="tag-w" v-for="(items,index) in item.repostMediaInfo.flags" :key="index">#{{items}}</span></div>
    <div class="img flex" v-if="!item.repostMediaInfo||(item.repostMediaInfo&&item.repostMediaInfo.mType==1)">

      <div class="img-bao" v-for="(items,index) in ((item.picList && item.picList.slice(0,6) || []))" :key="index" @click="bigImg(item, index)">
        <ImgDecypt class="list-img" :src="items" />
        <div class="img-layer" v-if="index==5&&item.picList.length>6">+{{item.picList.length-6}}</div>
        <!-- <div class="img-layer" v-else-if="item.price > 0 && index == item.picList.length - 1 && !item.isBuy"></div> -->
      </div>

    </div>
    <div class="video" v-else>
      <div tag="div" @click="videoJumpUserDetail(item.repostMediaInfo)" class="o-name">@{{item.repostMediaInfo&&item.repostMediaInfo.author.nickName}}</div>
      <div class="content">{{item.repostMediaInfo&&item.repostMediaInfo.content}}</div>
      <div class="o-img" @click="goFullPlay(item.repostMediaInfo)">
        <ImgDecypt class="o-imgshow" :src="item.repostMediaInfo&&item.repostMediaInfo.videoCover" />
        <div class="video-info flex-between">
          <div class="video-left"><span class="play"></span>{{item.repostMediaInfo&&item.repostMediaInfo.watchTimes|watchCount}}</div>
          <div class="video-right">{{item.repostMediaInfo&&item.repostMediaInfo.playTime|videotime}}</div>
        </div>
      </div>
    </div>
    <div class="pl flex-around">
      <div class="pl-left1 flex" @click="$emit('commentShow')"><span class="message"></span>{{item.commentNum|watchCount}}</div>
      <div class="pl-left2 flex" v-if="item.author.id !=userInfo.id" @click="like(item.bLike)"><span class="message zan" :class="{zaned:item.bLike}"></span>{{item.likeNum|watchCount}}</div>
      <div class="pl-left3 flex" @click="$emit('shareShow')"><span class="message"></span>{{item.shareNum|watchCount}}</div>
    </div>
    <transition name="shareShow">
      <div class="sw flex" v-if="bigImgShow">
        <div class="back" @click="bigImgShow=false">
          <span class="back-icon"></span>
        </div>
        <swiper class="swiper-all" :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(item,index) in item.picList" :key="index">
            <ImgDecypt class="ad-img" :src="item" />
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
    </transition>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { userCare, like } from "@/api/app.js";
import { jumpVideo } from "@/utils/index.js";
import { mapGetters } from "vuex";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },
  data() {
    return {
      isVip:
        JSON.parse(sessionStorage.getItem("userInfo")).vipExpire * 1000 >
        new Date().getTime()
          ? true
          : false, //判断是否为会员
      bigImgShow: false, //大图的显示与否
      swiperOption: {
        observeParents: true,
        observe: true,
        //显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许点击分页跳转
          type: "fraction",
        },
      },
      reivew: {
        2: require("@/assets/png/reviewUnder.png"), //待审核
        4: require("@/assets/png/reviewUnder.png"), //审核失败
      },
    };
  },
  methods: {
    goFullPlay(item) {
      jumpVideo(item);
    },
    bigImg(item, index) {
      // console.log(item)
      let data = {
        imgs: item.picList,
        index: index,
        info: item,
        uid: item.author.id
      }
      let dataStr = encodeURI(JSON.stringify(data))
      this.$router.push({
        name: "picturePreview",
        query: {
          data: this.encodeBase64(dataStr)
        },
      });
      //点击想看大图
      // if (!this.isVip) {
      //   //如果不是会员
      //   this.$emit("membershipShow");
      // } else {
        //如果是会员
        // this.bigImgShow = true;
        // this.$nextTick(() => {
        //   this.$refs.mySwiper.swiper.slideTo(index, 0, true); //切换到删除第二页的倒数第二条
        // });
      // }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, 'utf-8').toString('base64');
      return base64Str;
    },
    async like(prop) {
      //点赞
      let req = {
        isLike: !prop, //true为点赞，false为取消点赞
        mediaId: this.item.id,
      };
      let ret = await this.$Api(like, req);
      if (ret.code == 200) {
        if (!prop) {
          //如果prop为false 就是点赞
          this.$toast("点赞成功");
          this.item.bLike = true;
          this.item.likeNum++;
        } else {
          //取消点赞
          this.item.bLike = false;
          this.item.likeNum--;
        }
      } else {
        this.$toast(ret.tip);
      }
    },
    async userCare() {
      //关注
      let req = {
        isCare: true, //true为关注，false为取消关注
        userId: this.item.author.id,
      };
      let ret = await this.$Api(userCare, req);
      if (ret.code == 200) {
        this.$toast("关注成功");
        this.$emit("cared", this.item.author.id);
        // this.item.author.cared = true;
      }
    },
    // 跳转到详情页
    jumpUserDetail() {
      // item 4博主 1用户
      if (this.item.author.type == 4) {
        this.$router.push({
          path: "/bloggerDetails",
          query: {
            id: this.item.author.id,
          },
        });
        return;
      }
      if (this.item.author.type == 1) {
        this.$router.push({
          path: "/userDetails",
          query: {
            id: this.item.author.id,
          },
        });
        return;
      }
    },
    // video跳转
    videoJumpUserDetail(item) {
      // item 4博主 1用户
      if (item.author.type == 4) {
        this.$router.push({
          path: "/bloggerDetails",
          query: {
            id: item.author.id,
          },
        });
        return;
      }
      if (item.author.type == 1) {
        this.$router.push({
          path: "/userDetails",
          query: {
            id: item.author.id,
          },
        });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dynamic-list {
  margin-top: 10px;
  border-bottom: 1px solid #e0e5eb;
}
.header {
  position: relative;
  align-items: center;
  .review-box {
    position: absolute;
    right: 0;
    top: 0;
    img {
      width: 80px;
    }
  }
}
.left-header {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  margin-right: 13px;
  border: 1px solid #f21313;
}
.left {
  align-items: center;
}
.name {
  font-size: 13px;
}
.time {
  font-size: 10px;
  margin-top: 6px;
}
.right {
  padding: 4.9px 16px;
  background: $vermillion;
  color: #ffffff;
  border-radius: 18px;
}
.sec {
  margin: 10px 0;
  font-size: 11px;
  color: #585352;
}
.tag {
  height: 100%;
  margin-bottom: 5px;
}
.tag-w {
  padding: 3px 5px;
  border: 1px solid #fc1520;
  border-radius: 9px;
  color: #fc1520;
  margin-right: 10px;
}
.img {
  flex-wrap: wrap;
}
.list-img {
  width: 113px;
  height: 120px;
}
.img-bao {
  margin-right: 8px;
  border-radius: 4px;
  margin-top: 5px;
  overflow: hidden;
  position: relative;
}
.img-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  text-align: center;
  line-height: 120px;
  background: rgba($color: #000000, $alpha: 0.5);
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  // -webkit-backdrop-filter: blur(6px);	
  // backdrop-filter: blur(6px);	
}
.img-bao:nth-child(3n) {
  margin-right: 0;
}
.pl {
  margin: 10px 0;
  align-items: center;
}
.message {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  background: url("../../assets/png/comment.png") center center no-repeat;
  background-size: 100%;
}
.pl-left {
  align-items: center;
}
.pl-left2 > .message {
  background: url("../../assets/png/zan.png") center center no-repeat;
  background-size: 100%;
}
.pl-left3 > .message {
  background: url("../../assets/png/share.png") center center no-repeat;
  background-size: 100%;
}
.zan {
  margin-top: -3px;
}
.zaned {
  background: url("../../assets/png/zaned.png") center center no-repeat !important;
  background-size: 100% !important;
}
.sw {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  z-index: 14;
  justify-content: center;
  align-items: center;
}
.swiper-all {
  width: 100%;
  height: 100%;
}
.swiper-all /deep/ .van-image__img {
  object-fit: contain !important;
}
.swiper-all /deep/ .van-image {
  background: #000;
}
.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 52px;
  height: 52px;
  text-align: center;
  line-height: 62px;
  z-index: 11;
}
.back-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url("../../assets/png/back_icon.png") center center no-repeat;
  background-size: 100%;
}
.swiper-pagination {
  color: #ffffff;
}
.video {
  background: #eaeaea;
  overflow: hidden;
}
.o-name {
  margin-top: 12px;
  padding: 0 6px;
  color: #0893f8;
  font-size: 14px;
}
.content {
  margin-top: 6px;
  line-height: 18px;
  padding: 0 6px;
  color: #585352;
}
.o-img {
  position: relative;
  width: 290px;
  height: 162px;
  overflow: hidden;
  margin-top: 6px;
}
.o-imgshow {
  width: 100%;
  height: 100%;
}
.video-info {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  padding: 0 12px;
  align-items: center;
  color: #ffffff;
  font-size: 10px;
  box-sizing: border-box;
}
.play {
  display: inline-block;
  width: 8.9px;
  height: 9.4px;
  background: url("../../assets/png/play_icon.png") center center no-repeat;
  background-size: 100%;
  margin-right: 2px;
}
</style>