<template>
  <div class="blogger-posts">
    <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh" :refreshing="refreshing" :isNoData="isNoData" :firstLoading="firstLoading">
      <Dynamiclist :item="item" v-for="(item,index) in mediaInfos" :key="item.id" @cared="cared" @shareShow="shareS(item,index)" @commentShow="commentS(item,index)" @membershipShow="memberShow(item)" />
    </Pullrefresh>
    <comment v-show="commentShow" :topicInfo="memberInfo" @commentSuccess="commentSuccess" :commentShow="commentShow" @commentClose="commentShow=false" />
    <membership v-show="membershipShow" :topicInfo="memberInfo" :membershipShow="membershipShow" @membershipClose="membershipShow=false" />
    <share v-if="shareShow" :topicInfo="memberInfo" @shareSuccess="shareSuccess" :shareShow="shareShow" @shareClose="shareShow=false" />
  </div>
</template>
<script>
import share from "@/views/widget/share.vue";
import comment from "@/views/widget/comment.vue";
import membership from "@/views/widget/membership";
import { getUseDetail } from "@/api/on_demand.js";
import Pullrefresh from "@/views/widget/PullRefresh.vue";
import Dynamiclist from "@/views/widget/dynamicList.vue";
export default {
  components: {
    Dynamiclist,
    comment,
    membership,
    share,
    Pullrefresh,
  },
  data() {
    return {
      loading: false, //下滑loading
      finished: false, //数据是否加载完毕
      refreshing: false, //下拉下载loading
      isNoData: false, //数据是否为空
      firstLoading: true, //是否第一次加载
      // umt 1：原创 2:动态视频 3：粉丝福利 4:动态帖子 0:无效的占位符
      req: { userID: 0, umt: 4, pageNum: 1, pageSize: 10 },
      mediaInfos: [],
      commentShow: false, //评论是否显示
      membershipShow: false, //是否显示开通会员弹窗
      shareShow: false, //分享组件的显示与否
      memberInfo: {}, //开通会员弹窗需要的信息
    };
  },
  created() {
    this.getData();
  },
  methods: {
    cared(id){//博主关注成功回调 将图文列表的该作者的所有动态的关注状态改变
      this.mediaInfos.forEach((item) => {
        if(item.author.id == id){
          item.author.cared = true;//改变该博主的关注状态
        }
      });
    },
    async getData() {
      this.loading = true;
      let req = this.req;
      req.userID = Number(this.$route.query.id);

      let ret = await this.$Api(getUseDetail, this.req);
      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;

      if (ret.code == 200 && ret.data && ret.data.mediaInfos) {
        let mediaInfos = ret.data.mediaInfos;
        this.mediaInfos = this.mediaInfos.concat(mediaInfos);

        if (mediaInfos.length == 0 && this.req.pageNum == 1) {
          this.isNoData = true;
          return;
        }
        if (mediaInfos.length < this.req.pageSize) {
          this.finished = true;
        }
      }
    },
    onLoad() {
      this.req.pageNum++;
      this.getData();
    },
    onRefresh() {
      this.refreshing = true;
      this.finished = false;
      this.req.pageNum = 1;
      this.mediaInfos = [];
      this.getData();
    },
    shareS(item, index) {
      //监听分享点击事件
      this.index = index;
      this.shareShow = true;
      this.memberInfo = item;
    },
    shareSuccess() {
      //分享成功回调
      this.mediaInfos[this.index].shareNum++;
    },
    commentSuccess() {
      //评论成功评论条数加一
      this.mediaInfos[this.index].commentNum++;
    },
    commentS(item, index) {
      //监听评论点击事件
      this.index = index;
      this.commentShow = true;
      this.memberInfo = item;
    },
    memberShow(item) {
      //监听图片的点击事件
      this.membershipShow = true;
      this.memberInfo = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.blogger-posts {
  box-sizing: border-box;
  padding: 0 10px;
}
</style>